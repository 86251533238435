body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  color: #2b2b2b;
  background-color: #EAEAEA;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
}

.pure-menu {
  font-size: 12px;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  background: #fff;
  min-height: 4em;
}

.content a {
  font-weight: bold;
  color: #263C49;
  text-decoration: none;
  border-bottom: 1px dotted #18B9C1;
}

.content a:hover {
  text-decoration: none;
  color: #18B9C1;
}

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #18B9C1;
}

::selection {
  color: #fff;
  background: #18B9C1;
}

.pure-menu a,
.pure-menu a:visited,
.pure-menu a:focus {
  text-transform: uppercase;
  color: rgb(5, 7, 9);
  text-decoration: none;
  border-bottom: none;
  background: #fff;
}

.pure-menu a:hover {
  color: #C69731;
  background: #fff;
}

.pure-menu a.pure-menu-heading {
  color: #C69731;
  background: #fff;
  margin: 15px 0px 0px 0px;
}

.pure-menu-link {
  padding: 2em 1em;
}

.title {
  font-family: 'Roboto Slab', serif;
}

.section-title {
  padding-left: 15px;
}

.projects-section {
  padding-left: 15px;
}

.l-box {
 padding: 1.2em;
 background: #fff;
 margin: 20px 20px 20px 0px;
 font-size: 16px;
 line-height: 22px;
 min-height: 200px;
 border-radius: 2px;
 border: 1px solid rgba(43, 43, 43, 15%);
 border-radius: 1px;
}

.box-title a {
 color: rgb(5, 7, 9);
 text-decoration: none;
 border-bottom: none;
}

.lang-icon {
  padding-left: 5px;
}

.devicon-go-plain.colored {
  color: #57CFE0;
}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  /* Set your content font stack here: */
  font-family: 'Roboto', 'Helvetica', sans-serif;
}

#menu {
  background: #fff;
}

.custom-wrapper {
  background: #fff;
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  height: 2.1em;
  overflow: hidden;
  transition: height 0.5s;
  min-height: 4em;
}

.custom-wrapper.open {
  height: 15em;
}

.custom-menu-3 {
  text-align: right;
}

.custom-toggle {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background: #fff;
  border: none;
}

.custom-toggle .bar {
  background-color: #263C49;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 100px;
  position: absolute;
  top: 18px;
  right: 7px;
  transition: all 0.5s;
}

.custom-toggle .bar:first-child {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.custom-toggle.x .bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-toggle.x .bar:first-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 47.999em) {

  .custom-menu-3 {
      text-align: left;
  }

  .custom-toggle {
      display: block;
  }
}